import React from 'react'
import { Route, Switch } from 'react-router-dom'

import SizeFinderList from './SizeFinderList'
import SizeFinderImport from './SizeFinderImport'
import SizeFinderImportStatus from './SizeFinderImportStatus'

export default () => {
    return {
        routes: [
            {
                path: '/size-finder',
                render: ({ match }) => (
                    <Switch>
                        <Route path={`${match.url}`} exact component={SizeFinderList} />
                        <Route path={`${match.url}/import`} exact component={SizeFinderImport} />
                        <Route
                            path={`${match.url}/import/:id`}
                            exact
                            component={SizeFinderImportStatus}
                        />
                    </Switch>
                ),
            }
        ],
    }
}

