import React, { useEffect } from "react";
import TextInput from "@peracto/peracto-editor-ui/dist/TextInput";
import Textarea from "@peracto/peracto-editor-ui/dist/Textarea";
import Select from "@peracto/peracto-editor-ui/dist/Select";
import Checkbox from "@peracto/peracto-editor-ui/dist/Checkbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import { faPlusCircle } from "@fortawesome/pro-regular-svg-icons/faPlusCircle";

import sortBy from "lodash/sortBy";

const CardsForm = ({
    onChange,
    state: { cards, selectedCardIndex, border },
}) => {
    const DEFAULT_CARD = {
        icon: "headset",
        heading: "Card Heading",
        content: "Card content....",
        buttonText: "Click Here",
        url: "#",
        openInNewTab: false,
    };

    useEffect(() => {
        return () => {
            onChange({ selectedCardIndex: null });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {cards?.length === 0 && (
                <>
                    <p>Click 'Add Card' to get started</p>
                    <hr />
                </>
            )}
            {selectedCardIndex !== null &&
                cards &&
                cards[selectedCardIndex] && (
                    <>
                        <Select
                            label="Icon"
                            options={sortBy(
                                [
                                    {
                                        label: "Headset",
                                        value: "headset",
                                    },
                                    {
                                        label: "Lightbulb (Off)",
                                        value: "bulb",
                                    },
                                    {
                                        label: "Shield",
                                        value: "shield",
                                    },
                                    {
                                        label: "Trolley",
                                        value: "trolley",
                                    },
                                    {
                                        label: "Swatches",
                                        value: "swatches",
                                    },
                                    {
                                        label: "House",
                                        value: "house",
                                    },
                                    {
                                        label: "Lightbulb (On)",
                                        value: "bulbOn",
                                    },
                                    {
                                        label: "Ruler",
                                        value: "ruler",
                                    },
                                    {
                                        label: "Pencil",
                                        value: "pencil",
                                    },
                                    {
                                        label: "Screwdriver",
                                        value: "screwdriver",
                                    },
                                ],
                                "label"
                            )}
                            value={cards[selectedCardIndex].icon}
                            onChange={(e) => {
                                const cardsClone = [...cards];
                                cardsClone[selectedCardIndex].icon = e.value;

                                onChange({
                                    cards: cardsClone,
                                });
                            }}
                        />

                        <TextInput
                            label="Heading"
                            onChange={(e) => {
                                const cardsClone = [...cards];
                                cardsClone[selectedCardIndex].heading =
                                    e.target.value;

                                onChange({
                                    cards: cardsClone,
                                });
                            }}
                            value={cards[selectedCardIndex].heading}
                        />

                        <Textarea
                            label="Content"
                            onChange={(e, editor) => {
                                const cardsClone = [...cards];
                                cardsClone[selectedCardIndex].content =
                                    editor.getData();

                                onChange({
                                    cards: cardsClone,
                                });
                            }}
                            value={cards[selectedCardIndex].content}
                        />

                        <TextInput
                            label="Button Text"
                            onChange={(e) => {
                                const cardsClone = [...cards];
                                cardsClone[selectedCardIndex].buttonText =
                                    e.target.value;

                                onChange({
                                    cards: cardsClone,
                                });
                            }}
                            value={cards[selectedCardIndex].buttonText}
                        />

                        <TextInput
                            label="Button Link"
                            onChange={(e) => {
                                const cardsClone = [...cards];
                                cardsClone[selectedCardIndex].url =
                                    e.target.value;

                                onChange({
                                    cards: cardsClone,
                                });
                            }}
                            value={cards[selectedCardIndex].url}
                        />

                        <Checkbox
                            label="Open in new tab"
                            checked={cards[selectedCardIndex].openInNewTab}
                            onChange={(e) => {
                                const cardsClone = [...cards];
                                cardsClone[selectedCardIndex].openInNewTab =
                                    e.target.checked;
                                onChange({
                                    cards: cardsClone,
                                });
                            }}
                        />

                        <Checkbox
                            label="Show Border"
                            checked={border}
                            onChange={(e) =>
                                onChange({ border: e.target.checked })
                            }
                        />

                        <button
                            className="btn btn-sm btn-danger w-100 mt-2"
                            onClick={(e) => {
                                const cardsClone = [...cards];

                                cardsClone.splice(selectedCardIndex, 1);

                                onChange({
                                    cards: cardsClone,
                                });
                            }}
                        >
                            <FontAwesomeIcon icon={faTrash} className="mr-2" />{" "}
                            Remove Card
                        </button>
                    </>
                )}

            <hr className="my-2" />

            <button
                className="btn btn-sm btn-secondary w-100"
                onClick={(e) => {
                    e.preventDefault();
                    const cardsClone = [...cards, DEFAULT_CARD];
                    onChange({
                        cards: cardsClone,
                    });
                }}
            >
                <FontAwesomeIcon icon={faPlusCircle} className="mr-2" /> Add
                Card
            </button>
        </>
    );
};

export default CardsForm;
