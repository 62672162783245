/* eslint-disable */

import React, { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload } from '@fortawesome/pro-regular-svg-icons/faUpload'
import { Helmet } from 'react-helmet'

import {
    HydraTable,
    Heading,
    TextFilter,
    StyledCheckbox,
} from '@peracto/peracto-ui'

import { GET_LIST, useClient } from '@peracto/client'

const SizeFinderList = () => {
    // Default visible columns - key = column accessor
    const [selectedColumns, onSelectColumn] = useState()

    const { client } = useClient()

    // The 'selectable' property is used to define if the
    // column is available in the 'manage columns' dropdown
    const columns = useMemo(() => [
        {
            Header: 'Serial Number',
            accessor: 'serialNumber',
            sortType: 'basic',
            Filter: props => <TextFilter label="Serial Number" {...props} />,
            selectable: false,
        },
        {
            Header: 'Window Code',
            accessor: 'windowCode',
            sortType: 'basic',
            Filter: props => <TextFilter label="Window Code" {...props} />,
            selectable: false,
        },
        {
            Header: 'Window Height',
            accessor: 'windowHeight',
            sortType: 'basic',
            Filter: props => <TextFilter label="Window Height" {...props} />,
            selectable: false,
        },
        {
            Header: 'Window Width',
            accessor: 'windowWidth',
            sortType: 'basic',
            Filter: props => <TextFilter label="Window Width" {...props} />,
            selectable: false,
        }
    ])

    const onFetchData = options => client(GET_LIST, 'size-finder', options)

    return (
        <>
            <Helmet>
                <title>Size Finder | Peracto</title>
            </Helmet>
            <Heading name="Size Finder">
                <div className="ml-2 d-flex align-items-center">
                    <Link
                        className="mr-2 btn btn-outline-primary"
                        data-testid="import-size-finder-data"
                        to="/size-finder/import"
                    >
                        <FontAwesomeIcon icon={faUpload} className="mr-2" />
                        Import Size Finder Data
                    </Link>
                </div>
            </Heading>

            <div className="row">
                <div className="col">
                    <div className="card card--transparent">
                        <div className="card-body">
                            <HydraTable
                                columns={columns}
                                selectedColumns={selectedColumns}
                                onFetchData={onFetchData}
                                onSelectColumn={onSelectColumn}
                                defaultFilters={{
                                    fromUrl: '',
                                    toUrl: '',
                                    active: 'true',
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SizeFinderList
