import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRectanglePortrait } from "@fortawesome/pro-regular-svg-icons/faRectanglePortrait";
import { faHeadset } from "@fortawesome/pro-regular-svg-icons/faHeadset";
import { faSwatchbook } from "@fortawesome/pro-regular-svg-icons/faSwatchbook";
import { faShieldCheck } from "@fortawesome/pro-regular-svg-icons/faShieldCheck";
import { faShoppingCart } from "@fortawesome/pro-regular-svg-icons/faShoppingCart";
import { faLightbulb } from "@fortawesome/pro-regular-svg-icons/faLightbulb";
import { faHouse } from "@fortawesome/pro-regular-svg-icons/faHouse";
import { faLightbulbOn } from "@fortawesome/pro-solid-svg-icons/faLightbulbOn";
import { faRulerCombined } from "@fortawesome/pro-regular-svg-icons/faRulerCombined";
import { faPencil } from "@fortawesome/pro-regular-svg-icons/faPencil";
import { faScrewdriverWrench } from "@fortawesome/pro-light-svg-icons/faScrewdriverWrench";

const Icon = () => (
    <div className="flex py-10 justify-center items-center w-full">
        <FontAwesomeIcon icon={faRectanglePortrait} size="6x" />
    </div>
);

const icons = {
    headset: faHeadset,
    swatches: faSwatchbook,
    shield: faShieldCheck,
    trolley: faShoppingCart,
    bulb: faLightbulb,
    house: faHouse,
    bulbOn: faLightbulbOn,
    ruler: faRulerCombined,
    pencil: faPencil,
    screwdriver: faScrewdriverWrench,
};

const CardsComponent = ({
    state: { cards = [], selectedCardIndex = null, border },
    isEditMode,
    onChange,
    focused,
}) => {
    return (
        <>
            {cards?.length > 0 ? (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 content__icon-and-text gap-[30px]">
                    {cards.map((card, idx) => {
                        return (
                            <div
                                key={`card_${idx}`}
                                style={{
                                    boxShadow:
                                        selectedCardIndex === idx &&
                                        focused &&
                                        "inset 0 0 0 3px red",
                                }}
                                className={`flex flex-col items-center mb-0 bg-brand-white product-card p-[30px] border ${
                                    border
                                        ? "border-brand-grey"
                                        : "!border-transparent"
                                } rounded-[20px] items-start`}
                                onClick={(e) => {
                                    if (isEditMode) {
                                        e.preventDefault();
                                        onChange({ selectedCardIndex: idx });
                                    }
                                }}
                            >
                                <div className="flex flex-col items-center gap-[30px] h-full">
                                    <div className="flex items-center justify-center rounded-full bg-brand-secondary text-white h-[120px] w-[120px] text-[60px] ">
                                        <FontAwesomeIcon
                                            icon={icons[card.icon]}
                                        />
                                    </div>

                                    <div className="flex flex-col items-center flex-grow gap-[15px]">
                                        <h4 className="text-[18px] text-center leading-[24px] text-brand-black">
                                            {card.heading || ""}
                                        </h4>

                                        <p
                                            className="mb-0 text-center"
                                            dangerouslySetInnerHTML={{
                                                __html: card.content || "",
                                            }}
                                        />
                                    </div>

                                    {card.buttonText.length > 0 && (
                                        <button className="rounded-[100px] py-[10px] px-[30px] text-brand-secondary border !border-brand-secondary font-bold">
                                            {card.buttonText || ""}
                                        </button>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </div>
            ) : (
                <Icon />
            )}
        </>
    );
};

export default CardsComponent;
